import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => {
  const data = useStaticQuery(graphql`{
  avatarImage: file(relativePath: {eq: "cycling.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
}
`)

  return (
    <Layout hasHeader={false}>
      <Seo title="About Vancho Stojkov" />

      <section className="hero is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered is-centered is-text-centered">
              <div className="column is-half is-narrow">
                <h1 className="title is-2 pt-5">
                  Hey there!{" "}
                  <span role="img" aria-label="hi">
                    👋
                  </span>
                </h1>

                <p>
                  I'm Vancho Stojkov, an outdoor enthusiast{" "}
                  <span role="img" aria-label="biking" className="mr-1">
                    🚵‍♂️
                  </span>{" "}
                  and a software architect{" "}
                  <span role="img" aria-label="coder" className="mr-1">
                    🧑‍💻
                  </span>
                  .
                </p>

                <br />

                <GatsbyImage
                  image={data.avatarImage.childImageSharp.gatsbyImageData}
                  alt="Vancho Stojkov Cycling" />

                <br />

                <p>
                  JavaScript is my favorite programming language, and I enjoy designing systems and
                  working with React, Node.js, AWS,
                  Jamstack...
                  <br /><br />I also teach JS.
                </p>

                <br />
                <h3 className="title is-4">Connect</h3>

                <ul>
                  <li>
                    <a href="https://github.com/van100j">GitHub</a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/van100j/">Linkedin</a>
                  </li>
                  <li>
                    <a href="mailto:vancho@stojkov.me">Email</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default AboutPage
